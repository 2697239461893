<template>
    <div v-if="to=='#'">
         <a href="javascript:void(0)" @click="ShowDropdownMenu" :class="isActive?'active-sub-link': ''"  class="p-2 link-menu  my-1  flex justify-between items-center">
               <span  class="flex items-center"><i class="material-icons mx-2">{{ icon }}</i><span class="text-menu">{{ text }}</span></span>
        </a> 
    </div>
    <div v-else>
         <router-link :target="target" :to="to" @click.native="HideDropdownMenu"   class="p-2  my-1 link-menu flex justify-between items-center" :class="[submenu?'sub-link': '',children?'sub-sub-link': '']" >
           <span  class="flex items-center"><span class="img-link"><img style="height: 25px;" class="mx-2 img-storeino" v-if="img" :src="img" /><img style="height: 25px;" class="mx-2 img-storeino" v-if="imghover" :src="imghover" /></span><i v-if="icon" class="material-icons mx-2">{{ icon }}</i><span class="text-menu">{{ text }}</span></span>
        <!--<i v-if="!submenu" class="material-icons no-active">keyboard_arrow_right</i>
            <i v-if="!submenu" class="material-icons active">keyboard_arrow_down</i>-->
        </router-link>
    </div>
   
</template>
<script>
import $ from "jquery";
export default {
    props:{
        to: { type: String, default: '#' },
        text: { type: String, default: 'Link' },
        icon: { type: String, default: '' },
        img: { type: String, default: '' },
        imghover: { type: String, default: '' },
        submenu: false,
        children: false,
        target: false,
        isActive:  { type: Boolean, default: false },

    },
    async mounted() {
          if(this.isActive){
              $('.sub-sub-link').attr("data-show", "");
          }
    },
     methods: {
          ShowDropdownMenu() {
            const dropdownMenu = $(".sub-sub-link");

             this.$emit('myFilter');
             if(this.isActive){
                   $(dropdownMenu).removeAttr("data-show");
             }else{
                $(dropdownMenu).attr("data-show", "");
             }
        },
        HideDropdownMenu(){
              this.$emit('myFilter');
              const dropdownMenu = $(".sub-sub-link");
             $(dropdownMenu).removeAttr("data-show");
        }
     }
}
</script>
<style>
.hide-menu{
    visibility: hidden;
    margin-left: -280px;
}

.sub-link,.sub-sub-link {
   /* background-color:dodgerblue;*/
    margin: 0;
    padding-left: 30px;
}
.child .sub-link{
   padding-left: 40px;
}
.active-sub-link .sub-sub-link{
   display: block;
}
.sub-sub-link{
  display: none;
}
.sub-sub-link[data-show],.router-link-active.sub-sub-link {
  display: flex;
}
.router-link-active:not(.no-active) {
    /* font-weight:bold; */
    /* margin: 0; */
}
.material-icons.active{
    display: none;
}
.router-link-exact-active:not(.no-active){
    background-color: white;
    border-radius: 6px;
    color:#19a69d;
    margin: 0;
}
.router-link-active:not(.no-active) .material-icons.active{
    display: inline;
}
.router-link-active:not(.no-active) .material-icons.no-active{
    display: none;
}
</style>