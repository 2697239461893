var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.to == '#' ? _c('div', [_c('a', {
    staticClass: "p-2 link-menu my-1 flex justify-between items-center",
    class: _vm.isActive ? 'active-sub-link' : '',
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": _vm.ShowDropdownMenu
    }
  }, [_c('span', {
    staticClass: "flex items-center"
  }, [_c('i', {
    staticClass: "material-icons mx-2"
  }, [_vm._v(_vm._s(_vm.icon))]), _c('span', {
    staticClass: "text-menu"
  }, [_vm._v(_vm._s(_vm.text))])])])]) : _c('div', [_c('router-link', {
    staticClass: "p-2 my-1 link-menu flex justify-between items-center",
    class: [_vm.submenu ? 'sub-link' : '', _vm.children ? 'sub-sub-link' : ''],
    attrs: {
      "target": _vm.target,
      "to": _vm.to
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.HideDropdownMenu.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "flex items-center"
  }, [_c('span', {
    staticClass: "img-link"
  }, [_vm.img ? _c('img', {
    staticClass: "mx-2 img-storeino",
    staticStyle: {
      "height": "25px"
    },
    attrs: {
      "src": _vm.img
    }
  }) : _vm._e(), _vm.imghover ? _c('img', {
    staticClass: "mx-2 img-storeino",
    staticStyle: {
      "height": "25px"
    },
    attrs: {
      "src": _vm.imghover
    }
  }) : _vm._e()]), _vm.icon ? _c('i', {
    staticClass: "material-icons mx-2"
  }, [_vm._v(_vm._s(_vm.icon))]) : _vm._e(), _c('span', {
    staticClass: "text-menu"
  }, [_vm._v(_vm._s(_vm.text))])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }